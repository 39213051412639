* {
  margin: 0;
}

button,
a {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body,
html,
#root {
  height: 100%;
}

/* There is issue related with "react-scripts" version. After saving code in "dev" mode
it shows transparent modal window and requires full page reload to make it dissappear.
For correct fix it should be migrated to version 5.
The migration requires time because it has no backward compatibility. */
body > iframe:not([src])[style*='z-index: 2147483647'] {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
